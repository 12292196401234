import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ComposerRepository from "@/abstraction/repository/composerRepository";

const repository = new ComposerRepository();

export default function useArtistList() {
    // Use toast
    const toast = useToast();

    const artists = ref([]);
    const pagination = ref([]);

    // Table Handlers
    const tableColumns = [
        { key: "user", sortable: true },
        { key: "email", sortable: true },
        // { key: 'role', sortable: true },
        { key: "actions" },
    ];
    const perPage = ref(10);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);

    const storeComposer = async (data) => {
        try {
            return await repository.addSlideShow(data);
        } catch (e) {
            return false;
        }
    };
    
    const fetchArtists = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const artistsList = await repository.index({ filters, pagination: paginate });
        artists.value = artistsList.data;
        pagination.value = artistsList.pagination;
        return artists;
    };
    // const fetchArtists = async (pagination = { page: 1 , itemsPerPage: perPage.value}) => await repository.index({ pagination })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = (role) => {
        if (role === "subscriber") return "primary";
        if (role === "author") return "warning";
        if (role === "maintainer") return "success";
        if (role === "editor") return "info";
        if (role === "admin") return "danger";
        return "primary";
    };

    const resolveUserRoleIcon = (role) => {
        if (role === "subscriber") return "UserIcon";
        if (role === "author") return "SettingsIcon";
        if (role === "maintainer") return "DatabaseIcon";
        if (role === "editor") return "Edit2Icon";
        if (role === "admin") return "ServerIcon";
        return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
        if (status === "pending") return "warning";
        if (status === "active") return "success";
        if (status === "inactive") return "secondary";
        return "primary";
    };

    return {
        fetchArtists,
        tableColumns,
        perPage,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        pagination,
        artists,
        storeComposer,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    };
}
