<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-1 mb-2"
        >
          <label>Name</label>
          <b-form-input
              id="name"
              v-model="form.name.val"
              class="d-inline-block mr-1"
          />
        </b-col>
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--          class="mb-md-1 mb-2"-->
        <!--        >-->
        <!--          <label>Role</label>-->
        <!--          <v-select-->
        <!--            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--            :value="roleFilter"-->
        <!--            :options="roleOptions"-->
        <!--            class="w-100"-->
        <!--            :reduce="(val) => val.value"-->
        <!--            @input="(val) => $emit('update:roleFilter', val)"-->
        <!--          />-->
        <!--        </b-col>-->
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--          class="mb-md-1 mb-2"-->
        <!--        >-->
        <!--          <label>Plan</label>-->
        <!--          <v-select-->
        <!--            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--            :value="planFilter"-->
        <!--            :options="planOptions"-->
        <!--            class="w-100"-->
        <!--            :reduce="(val) => val.value"-->
        <!--            @input="(val) => $emit('update:planFilter', val)"-->
        <!--          />-->
        <!--        </b-col>-->
        <b-col
            cols="12"
            md="4"
            class="mb-md-1 mb-2"
        >
          <label>Country</label>
          <v-select
              v-model="form.country_id.val"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="getCountries"
              label="name"
              :clearable="true"
              input-id="country"
              @option:selected="changeCountry"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-1 mb-2"
        >
          <label>City</label>
          <v-select
              v-model="form.city_id.val"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="cities"
              :clearable="true"
              label="name"
              input-id="city"
          />
        </b-col>
        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="4"-->
        <!--          class="mb-md-1 mb-2"-->
        <!--        >-->
        <!--          <label>Status</label>-->
        <!--          <v-select-->
        <!--            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--            :value="statusFilter"-->
        <!--            :options="statusOptions"-->
        <!--            class="w-100"-->
        <!--            :reduce="(val) => val.value"-->
        <!--            @input="(val) => $emit('update:statusFilter', val)"-->
        <!--          />-->
        <!--        </b-col>-->
      </b-row>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="danger"
              class="mt-1"
              @click="filterArtist"
          >
            Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ref} from "@vue/composition-api";
import useComposerList from "./useComposerList";
import {avatarText} from "@core/utils/filter";
import Ripple from 'vue-ripple-directive'
import {mapActions, mapGetters} from "vuex";
import CityRepository from "@/abstraction/repository/cityRepository";
const cityRepository = new CityRepository()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters('country', ['getCountries']),
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const isLoading = ref()
    const blankForm = {
      name: {
        type: 'like',
        val: null,
      },
      country_id: {
        type: 'like',
        val: null,
      },
      city_id: {
        type: 'like',
        val: null,
      },
    }
    const cities = ref([])
    const form = ref(blankForm)
    const {
      fetchArtists,
      tableColumns,
      perPage,
      pagination,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      artists,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    } = useComposerList()


    return {
      form,
      isLoading,
      cities,
      // Sidebar
      fetchArtists,
      pagination,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      artists,
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }

  },
  methods:{
    ...mapActions('country', ['loadCountries']),
    async changeCountry() {
      if (this.form.country_id.val) {
        const resoruce = await cityRepository.index(this.form.country_id.val)
        this.cities = resoruce.data
      }
    },
    filterArtist() {
      this.$emit('filter', {page: 1, itemsPerPage: this.perPage}, this.form)
    }
  },
  created() {
    this.loadCountries()
  },


}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
